import './App.css';
import { Flex } from '@chakra-ui/react'
import scales from './assets/gif.gif'
import trusted from './assets/trans_trusted__.png'


function App() {
  return (
    <div className="App">
      <Flex justify="center" align="center" paddingTop="0px">
        <img src={scales} alt=''/>
      </Flex>
      <Flex justify="center" align="center" paddingTop="0px">
        <img src={trusted} alt=''/>
      </Flex>
    </div>
  );
}

export default App;